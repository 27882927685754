import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/views/Layout/DashboardLayout";
import Charts from "@/components/views/Pages/Charts";
import AuthBasicLayout from "@/views/Layout/AuthBasicLayout";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import UserDetail from "@/views/Pages/Profile/UserDetail.vue";
import StudentDetail from "@/views/Pages/Students/StudentDetail.vue";
import OnlineSurgalt from "@/views/Pages/Works/OnlineSurgalt.vue";
import OnlineVideos from "@/views/Pages/Works/OnlineVideos.vue";
import SchoolRegistration from "@/views/SchoolAdmins/SchoolRegistration.vue";
import DashboardAnalysis from "@/views/Pages/Works/DashboardAnalysis.vue";
import EyeshAnalysis from "@/views/Pages/Works/EyeshAnalysis.vue";

const Dashboard = () => import("@/views/Dashboard/Dashboard.vue");
const PageLesson = () => import("@/views/Dashboard/PageLesson.vue");
const PageLessonESIS = () => import("@/views/Dashboard/PageLessonESIS.vue");
const PageLessonScore = () => import("@/views/Dashboard/PageLessonScore.vue");
const PageLessons = () => import("@/views/Dashboard/PageLessons.vue");
const PageLessonsTMP = () => import("@/views/Dashboard/PageLessonsTMP.vue");

const SDepartments = () => import("@/views/Dashboard/SDepartments.vue");
const LessonCategoriesByDepartments = () =>
  import("@/views/Dashboard/LessonCategoriesByDepartments.vue");

const SettingTables = () => import("@/views/Dashboard/SettingTables.vue");

const LessonCategories = () => import("@/views/Dashboard/LessonCategories.vue");

const PageLessonPreview = () =>
  import("@/views/Dashboard/PageLessonPreview.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");

const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");

const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");

const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

const Calendary = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Dashboard/Calendary.vue");

const Login = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Basic.vue"
  );
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");

const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Employees = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/Employees.vue"
  );

const EmployeesSuper = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/EmployeesSuper.vue"
  );
const Students = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/Students.vue"
  );
const AllLessonCategories = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/AllLessonCategories.vue"
  );
const PageLessonAttendanceChecking = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/PageLessonAttendanceChecking.vue"
  );

const TeacherAllIrzuud = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/TeacherAllIrzuud.vue"
  );

const Unelgee1 = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Scores/Unelgee1.vue"
  );

const PageLessonAttendanceCheckingForClass = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/PageLessonAttendanceCheckingForClass.vue"
  );
const LessonIrz = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/LessonIrz.vue"
  );
const LessonSessionScores = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/LessonSessionScores.vue"
  );

const PageLessonHomeWorkChecking = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Dashboard/PageLessonHomeWorkChecking.vue"
  );

const PageLessonActivityChecking = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Dashboard/PageLessonActivityChecking.vue"
  );

const PageRequests = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/PageRequests.vue"
  );
const PageRequestsMonitoring = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Irz/PageRequestsMonitoring.vue"
  );
const PageDaalgavar = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Dashboard/Widgets/TopicDaalgavarTable.vue"
  );
//myclass
// const MyClassStudents = () =>
//   import("@/views/Pages/MyClass/MyClassStudents.vue");
const MyNClassesUnelgee2 = () =>
  import("@/views/Pages/MyClass/MyNClassesUnelgee2.vue");
const MyNClassesUnelgee2Report = () =>
  import("@/views/Pages/MyClass/MyNClassesUnelgee2Report.vue");

const MyNClassesStudents = () =>
  import("@/views/Pages/MyClass/MyNClassesStudents.vue");

const MyNClassesStudentsDetails = () =>
  import("@/views/Pages/MyClass/MyNClassesStudentsDetails.vue");

const MyNClassesLessons = () =>
  import("@/views/Pages/MyClass/MyNClassesLessons.vue");

const MyNClassesLessonsSongolt = () =>
  import("@/views/Pages/MyClass/MyNClassesLessonsSongolt.vue");

const MyNClassesCalendars = () =>
  import("@/views/Pages/MyClass/MyNClassesCalendars.vue");
const MyNClassesIrz = () => import("@/views/Pages/MyClass/MyNClassesIrz.vue");

const MyClassStudentsAttendance = () =>
  import("@/views/Pages/MyClass/MyClassStudentsAttendance.vue");
const ClassStudentsAttendance = () =>
  import("@/views/Pages/Irz/ClassStudentsAttendance.vue");

const MyClassStudentsScores = () =>
  import("@/views/Pages/MyClass/MyClassStudentsScores.vue");

const PageAssignments = () =>
  import("@/views/Pages/Assignments/PageAssignments.vue");

const PageSorilAssignments = () =>
  import("@/views/Pages/Assignments/PageSorilAssignments.vue");
const PageSorilAssignmentsByGrandit = () =>
  import("@/views/Pages/Assignments/PageSorilAssignmentsByGrandit.vue");
const PageSorilAssignmentsByGrandit2 = () =>
  import("@/views/Pages/Assignments/PageSorilAssignmentsByGrandit2.vue");
const StudentsLessons = () =>
  import("@/views/Pages/Students/StudentsLessons.vue");

const StudentsSorils = () =>
  import("@/views/Pages/Students/StudentsSorils.vue");

//for students

const StudentLessonDetail = () =>
  import("@/views/Pages/Students/StudentLessonDetail.vue");
const StudentSelectLessons = () =>
  import("@/views/Pages/Students/StudentSelectLessons.vue");
const StudentShowAssignmentResults = () =>
  import("@/views/Pages/Students/StudentShowAssignmentResults.vue");

const StudentShowAssignmentAllResults = () =>
  import("@/views/Pages/Assignments/XShowAssignmentAllResults.vue");
const StudentShowAssignmentAllResultsDetail = () =>
  import("@/views/Pages/Assignments/XShowAssignmentAllResultsDetails.vue");
const PublicStudentsScore = () =>
  import("@/views/Pages/Assignments/PublicStudentsScore.vue");
//Analyzing....

const AllQuestionsAnalysis = () =>
  import("@/views/Pages/Analyzes/AllQuestionsAnalysis.vue");
const ReportSchool = () => import("@/views/Pages/Analyzes/ReportSchool.vue");

import AttendanceAnalysis from "@/views/Pages/Irz/AttendanceAnalysis.vue";

const PageProblemRegister = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/PageProblemRegister.vue"
  );

const PageXEelj = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Profile/PageXEelj.vue"
  );

Vue.use(Router);
let OrganizationSetting = {
  path: "/pages",
  component: DashboardLayout,
  name: "OrganizationSetting",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/userdetail/:userrefid",
      name: "UserDetail",
      component: UserDetail,
      props: true,
      meta: {
        requiresAuth: false,
        groupName: "Pages",
      },
    },

    {
      path: "/studentdetail/:userrefid",
      name: "StudentDetail",
      component: StudentDetail,
      props: true,
      meta: {
        requiresAuth: false,
        groupName: "Pages",
      },
    },
    {
      path: "/onlinesurgalt",
      name: "OnlineSurgalt",
      component: OnlineSurgalt,
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/onlinevideos",
      name: "OnlineVideos",
      component: OnlineVideos,
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/tutorialvideos",
      name: "TutorialVideos",
      component: () => import("@/views/Pages/Works/TutorialVideos.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },

    {
      path: "/schoolunelgeeregistration",
      name: "SchoolUnelgeeRegistration",
      component: () =>
        import("@/views/SchoolAdmins/SchoolGradingMonitoring.vue"),
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },

    {
      path: "/schoolnullunelgeeregistration",
      name: "SchoolNullUnelgeeRegistration",
      component: () =>
        import("@/views/SchoolAdmins/SchoolNullGradingMonitoring.vue"),
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/schoolretrytimeunelgeeregistration",
      name: "SchoolRetryTimeUnelgeeRegistration",
      component: () =>
        import("@/views/SchoolAdmins/SchoolRetryTimeGradingMonitoring.vue"),
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/schoolregistration",
      name: "SchoolRegistration",
      component: SchoolRegistration,
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/schoolcrm",
      name: "SchoolCRM",
      component: () => import("@/views/SchoolAdmins/SchoolCRM.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/schooltokens",
      name: "SchoolCRM",
      component: () => import("@/views/SchoolAdmins/SchoolTokens.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/kindergartencrm",
      name: "KinderGartenCRM",
      component: () => import("@/views/SchoolAdmins/KinderGartenCRM.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/schoolgradingproblemmonitoring",
      name: "SchoolGradingProblemMonitoring",
      component: () =>
        import("@/views/SchoolAdmins/SchoolGradingProblemMonitoring.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },

    {
      path: "/onlineclasuperadminmonitoringstudents",
      name: "SuperadminMonitoringStudents",
      component: () =>
        import("@/sms/admins/monitoring/SuperadminMonitoringStudents.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/onlineclasuperadminmonitoringunelgee2",
      name: "SuperadminMonitoringStudents",
      component: () =>
        import("@/sms/admins/monitoring/SuperadminMonitoringUnelgee2.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },

    {
      path: "/failingstudentsunelgee2",
      name: "FailingStudentsUnelgee2",
      component: () =>
        import("@/views/SchoolAdmins/FailingStudentsUnelgee2.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/dashboardanalysis",
      name: "DashboardAnalysis",
      component: DashboardAnalysis,
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/eyeshanalysis",
      name: "EyeshAnalysis",
      component: EyeshAnalysis,
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/pageproblemregister",
      name: "PageProblemRegister",
      component: PageProblemRegister,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/esissystem",
      name: "EsisSystem",
      component: () => import("@/_esis/EsisSystem.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/esisstudents",
      name: "EsisStudents",
      component: () => import("@/_esis/EsisStudents.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/esisteachersmovement",
      name: "EsisTeacherMovement",
      component: () => import("@/_esis/EsisTeacherMovement.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
  ],
};
let applicationPages = {
  path: "/pages",
  component: DashboardLayout,
  name: "Application",
  children: [
    // {
    // 	path: "/pages/applications/datatables",
    // 	name: "Datatables",
    // 	component: Datatables,
    // 	meta: {
    // 		groupName: "Applications",
    // 	},
    // },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};
let authBasicPages = {
  path: "/pages",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/pages/authentication/login",
      name: "Login",
      component: Login,
    },
  ],
};

let profilePages = {
  path: "/pages",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};
let userPages = {
  path: "/pages",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/hr/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/pages/TsagiinBurtgel",
      name: "TsagiinBurtgel",
      component: () => import("@/views/Hr/Setting/TsagiinBurtgel.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/employees",
      name: "Employees",
      component: Employees,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/employeessuper",
      name: "EmployeesSuper",
      component: EmployeesSuper,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/beltgel",
      name: "TeachersInfoShared",
      component: () => import("@/views/Pages/Profile/TeachersInfoShared.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/teachers",
      name: "Teachers",
      component: () => import("@/views/Pages/Profile/Teachers.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/assignmenttable",
      name: "AssignmentTable",
      component: () => import("@/views/Pages/Profile/TeacherLessonTable.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/students",
      name: "Students",
      component: Students,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/tailanreports",
      name: "TailanReport",
      component: () => import("@/views/Pages/Profile/TailanReports.vue"),

      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/paymentmonitoring",
      name: "PaymentMonitoring",
      component: () => import("@/views/Pages/Profile/PaymentMonitoring.vue"),

      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagexeelj",
      name: "PageXEelj",
      component: PageXEelj,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/schoolsettings",
      name: "PageSchoolSettings",
      component: () => import("@/sms/admins/PageSchoolSettings.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/lessonunelgee",
      name: "LessonUnelgee",
      component: () => import("@/unelgee/LessonUnelgee.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/lessonhuvaari",
      name: "LessonHuvaari",
      component: () => import("@/sms/huvaari/LessonHuvaari.vue"),
      props: true,
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/pages/classgroupcalendary",
      name: "ClassGroupCalendary",
      component: () => import("@/sms/huvaari/ClassGroupCalendary.vue"),
      props: true,
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },
    {
      path: "/irz/classirzweekly",
      name: "",
      component: () => import("@/views/Pages/Irz/ClassIrzWeekly.vue"),
      props: true,
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/pages/teachershuvaari",
      name: "TeachersHuvaari",
      component: () => import("@/sms/huvaari/TeachersHuvaari.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/TeacherLessonHuvaari",
      name: "TeacherLessonHuvaari",
      component: () => import("@/sms/huvaari/TeacherLessonHuvaari.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/lessonhuvaariedit",
      name: "LessonHuvaariEdit",
      component: () => import("@/sms/huvaari/LessonHuvaariEdit.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pages/pages/lessonhuvaariview",
      name: "LessonHuvaariView",
      component: () => import("@/sms/huvaari/LessonHuvaariView.vue"),
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },

    {
      path: "/pages/pages/unelgeedetail/:unelgeeId",
      name: "UnelgeeDetail",
      component: () => import("@/unelgee/UnelgeeDetail.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/curriculum",
      name: "Curriculum",
      component: () => import("@/sms/sekz/Curriculum.vue"),
      props: true,
      meta: {
        groupName: "Pages",
        requiresAuth: true,
      },
    },
    {
      name: "XOnlineRooms",
      path: "/xonlinerooms",
      component: () => import("@/sms/XOnlineRooms.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      name: "ContentDatabase",
      path: "/lms/contentdatabase",
      component: () => import("@/sms/contentdatabase/ContentDatabase.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      name: "AssignmentDatabase",
      path: "/lms/assignmentdatabase",
      component: () => import("@/sms/contentdatabase/AssignmentDatabase.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/alllessoncategories",
      name: "AllLessonCategories",
      component: AllLessonCategories,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagelessonattendancechecking",
      name: "PageLessonAttendanceChecking",
      component: PageLessonAttendanceChecking,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/teacherallirzuud",
      name: "TeacherAllIrzuud",
      component: TeacherAllIrzuud,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagejurnal",
      name: "PageJurnal",
      component: () => import("@/views/Pages/Irz/PageJurnal.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentsscorechecking",
      name: "Unelgee1",
      component: Unelgee1,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/yearexamination",
      name: "YearExamination",
      component: () => import("@/views/Pages/Scores/YearExamination.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/teachingplan",
      name: "TeachingPlan",
      component: () => import("@/views/Pages/Teachers/TeachingPlan.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/informproblems",
      component: () => import("@/sms/InformProblems.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/systemplanning",
      component: () => import("@/sms/SystemPlanning.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentsscorechecking2",
      name: "Unelgee2",
      component: () => import("@/views/Pages/Scores/Unelgee2.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentsscoreconfirming2/:zClassGroupRefPath",
      name: "Unelgee2Confirmations",
      component: () => import("@/views/Pages/Scores/Unelgee2Confirmations.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentsscoreconfirming22/:zClassGroupRefPath/:selectedHalfYearId",
      name: "Unelgee22Confirmations",
      component: () =>
        import("@/views/Pages/Scores/Unelgee22Confirmations.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/unelgee2analytics",
      name: "Unelgee2Analytics",
      component: () => import("@/views/Pages/Scores/Unelgee2Analytics.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/unelgee22analytics",
      name: "Unelgee22AnalyticsManager",
      component: () =>
        import("@/views/Pages/Scores/Unelgee22AnalyticsManager.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentsscoreconfirming2old",
      name: "Unelgee2ConfirmationsOld",
      component: () =>
        import("@/views/Pages/Scores/Unelgee2ConfirmationsOld.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/classselectionunelgee",
      name: "Unelgee2ClassSelection",
      component: () =>
        import("@/views/Pages/Scores/Unelgee2ClassSelection.vue"),
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/unelgee2managermonitoring",
      name: "Unelgee2ManagerMonitoring",
      component: () =>
        import("@/views/Pages/Scores/Unelgee2ManagerMonitoring.vue"),
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagelessonattendancecheckingforclass",
      name: "PageLessonAttendanceCheckingForClass",
      component: PageLessonAttendanceCheckingForClass,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/hourlyclassattendanceformanager",
      name: "HourlyClassAttendanceForManager",
      component: () =>
        import("@/views/Pages/Irz/HourlyClassAttendanceForManager.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/dailyclassattendance",
      name: "DailyClassAttendance",
      component: () => import("@/views/Pages/Irz/DailyClassAttendance.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/myclass/mynclassesdailyirz",
      name: "MyNClassesDailyIrz",
      component: () => import("@/views/Pages/MyClass/MyNClassesDailyIrz.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/monthlyclassattendance",
      name: "MonthlyClassAttendance",
      component: () => import("@/views/Pages/Irz/MonthlyClassAttendance.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/studentattendancechecking",
      name: "StudentAttendanceChecking",
      component: () =>
        import("@/views/Pages/Students/StudentAttendanceChecking.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/attendancecheckingstatistics",
      name: "AttendanceCheckingStatistics",
      component: () => {
        import("@/views/Pages/Irz/AttendanceCheckingStatistics.vue");
      },
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/showallassignmentresults",
      name: "ShowAllAssignmentResults",
      component: () =>
        import("@/views/Pages/Assignments/XShowAllAssignmentResults.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/lessonirz/:lessonObject",
      name: "LessonIrz",
      component: LessonIrz,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/lessonsessionscores/:lessonObject",
      name: "LessonSessionScores",
      component: LessonSessionScores,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagelessonactivitychecking",
      name: "PageLessonActivityChecking",
      component: PageLessonActivityChecking,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/pagelessonhomeworkchecking",
      name: "PageLessonHomeWorkChecking",
      component: PageLessonHomeWorkChecking,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/requests",
      name: "PageRequests",
      component: PageRequests,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/pagerequestsmonitoring",
      name: "PageRequestsMonitoring",
      component: PageRequestsMonitoring,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/mynclassesstudentsirzconfirmations",
      name: "",
      component: () =>
        import("@/views/Pages/MyClass/MyNClassesStudentsIrzConfirmations.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/classstudentsirzconfirmation",
      name: "",
      component: () => import("@/sms/manager/ClassStudentsIrzConfirmation.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/doctorinspects",
      name: "",
      component: () => import("@/sms/doctor/DoctorInspects.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/scoringdaalgavar",
      name: "PageDaalgavar",
      component: PageDaalgavar,
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/scoringdaalgavarclassgroup/:classGroupPath/:daalgavarPath",
      name: "ScoringDaalgavar",
      component: () => import("@/sms/daalgavar/ScoringDaalgavar.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentdetail/",
      name: "UserDataDetail",
      component: () => import("@/views/Pages/Students/UserDataDetail.vue"),
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/pages/pages/scoringexam/:classGroupPath/:examPath",
      name: "ScoringExam",
      component: () => import("@/sms/exams/ScoringExam.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    //admins
    {
      path: "/pages/dashboards/jijuurbagsh",
      name: "StudentSelectLessons",
      component: () =>
        import("@/views/Pages/Students/StudentSelectLessons.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/dashboards/implementationmonitoring2",
      name: "ImplementationMonitoring2",
      component: () =>
        import("@/sms/admins/monitoring/ImplementationMonitoring2.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/dashboards/jijuuradmin",
      name: "JijuurTeacherHuvaarilalt",
      component: () => import("@/sms/admins/JijuurTeacherHuvaarilalt.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/dashboards/FinalYearClosingPage",
      name: "FinalYearClosingPage",
      component: () => import("@/sms/admins/FinalYearClosingPage.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/dashboards/FinalYearClosingPageTeacher",
      name: "FinalYearClosingPageTeacher",
      component: () => import("@/sms/admins/FinalYearClosingPageTeacher.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/pages/dashboards/classgroupsirzweekly",
      name: "ClassGroupsIrzWeekly",
      component: () => import("@/sms/admins/ClassGroupsIrzWeekly.vue"),
      meta: {
        groupName: "Dashboards",
        requiresAuth: true,
      },
    },
    {
      path: "/accesslinks",
      name: "AccessLinks",
      props: true,
      component: () => import("@/sms/admins/AccessLinks.vue"),
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },

    {
      path: "/rolemanagement",
      name: "RoleManagement",
      props: true,
      component: () => import("@/sms/admins/RoleManagement.vue"),
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/settingtables",
      name: "SettingTables",
      component: SettingTables,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga",
      name: "Asuulga",
      component: () => import("@/views/Dashboard/PageAsuulga.vue"),
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/xadetail/:asuulgaId",
      name: "XADetail",
      component: () => import("@/views/Dashboard/XADetail.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga/editing/:asuulgaPath",
      name: "AsuulgaEditing",
      component: () => import("@/sms/asuulga/AsuulgaEditing.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulguud",
      name: "Asuulguud",
      component: () => import("@/sms/asuulga/Asuulguud.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/asuulga/results/:sorilId",
      name: "AsuulgaResults",
      component: () => import("@/sms/asuulga/AsuulgaResults.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga/asuulgaresultsmanagerpergroup/:sorilId",
      name: "AsuulgaResultsManagerPerGroup",
      component: () =>
        import("@/sms/asuulga/AsuulgaResultsManagerPerGroup.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga/resultsmanager/:sorilId",
      name: "AsuulgaResultsManager",
      component: () => import("@/sms/asuulga/AsuulgaResultsManager.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/asuulga/resultsclassteacher/:sorilId",
      name: "AsuulgaResultsClassTeacher",
      component: () => import("@/sms/asuulga/AsuulgaResultsClassTeacher.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga/resultsteacher/",
      name: "AsuulgaResultsTeacher",
      component: () => import("@/sms/asuulga/AsuulgaResultsTeacher.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },

    {
      path: "/asuulga/teachers/:sorilId",
      name: "AsuulgaTeachers",
      component: () => import("@/sms/asuulga/AsuulgaTeachers.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/asuulga/asuulgacxAnswer/:sorilId",
      name: "AsuulgaCXAnswer",
      component: () => import("@/sms/asuulga/AsuulgaCXAnswer.vue"),
      props: true,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/studentshowassignmentallresults/:sorilId/:maxNumber",
      name: "StudentShowAssignmentAllResults",
      component: StudentShowAssignmentAllResults,
      meta: {
        groupName: "Pages",
      },
      props: true,
    },

    {
      path: "/pages/pages/lessonexamresults/:sorilId",
      name: "LessonExamResults",
      component: () =>
        import("@/views/Pages/Assignments/LessonExamResults.vue"),
      meta: {
        groupName: "Pages",
      },
      props: true,
    },

    // {
    // 	path: '/asuulga/asuulgaanswering/:asuulgaRefPath/:bulegId/:teacherName/:teacherRefPath/:teacherLessons',
    // 	name: 'AsuulgaAnswering',
    // 	props: true,
    // 	component: () => import("@/sms/asuulga/AsuulgaAnswering"),
    // },
    {
      path: "/asuulga/asuulgaanswering/:asuulgaRefPath",
      name: "AsuulgaAnswering",
      props: true,
      component: () => import("@/sms/asuulga/AsuulgaAnswering"),
    },
    {
      path: "/classstudentsattendance",
      name: "ClassStudentsAttendance",
      component: ClassStudentsAttendance,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

let myClassPages = {
  path: "/myclass",
  component: DashboardLayout,
  name: "MyClass",
  children: [
    {
      path: "mynclassesunelgee2",
      name: "",
      component: MyNClassesUnelgee2,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclassesunelgee2report",
      name: "",
      component: MyNClassesUnelgee2Report,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclassesstudents",
      name: "MyNClassesStudents",
      component: MyNClassesStudents,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclassesstudentsdetails",
      name: "MyNClassesStudentsDetails",
      component: MyNClassesStudentsDetails,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "myclassstudentsattendance",
      name: "MyClassStudentsAttendance",
      component: MyClassStudentsAttendance,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },

    {
      path: "mynclasseslessons",
      name: "MyNClassesLessons",
      component: MyNClassesLessons,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclasseslessonssongolt",
      name: "MyNClassesLessons",
      component: MyNClassesLessonsSongolt,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclassescalendars",
      name: "MyNClassesCalendars",
      component: MyNClassesCalendars,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
    {
      path: "mynclassesirz",
      name: "MyNClassesIrz",
      component: MyNClassesIrz,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },

    {
      path: "myclassstudentsscores",
      name: "MyClassStudentsScores",
      component: MyClassStudentsScores,
      meta: {
        groupName: "MyClass",
        requiresAuth: true,
      },
    },
  ],
};

let cooking = {
  path: "/cooking",
  component: DashboardLayout,
  name: "CookingProducts",
  children: [
    {
      path: "cookingproducts",
      name: "CookingProducts",
      component: () => import("@/cooking/CookingProducts"),
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "pos",
      name: "Pos",
      component: () => import("@/cooking/Pos.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "cookinghistory",
      name: "CookingHistory",
      component: () => import("@/cooking/CookingHistory.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "cookinganalysis",
      name: "CookingAnalysis",
      component: () => import("@/cooking/CookingAnalysis.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },

    {
      path: "cookingproviders",
      name: "cookingproviders",
      component: () => import("@/cooking/CookingProviders.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },

    {
      path: "cookingbuyproducts",
      name: "CookingBuyProducts",
      component: () => import("@/cooking/CookingBuyProducts.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },

    {
      path: "cookingpageproblemregister",
      name: "CookingProblemRegister",
      component: () => import("@/cooking/CookingProblemRegister.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },

    {
      path: "cookinghuvaari",
      name: "CookingHuvaari",
      component: () => import("@/cooking/CookingHuvaari.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "cookinghuvaari2",
      name: "CookingHuvaari2",
      component: () => import("@/cooking/CookingHuvaari2.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "cookingingredients/:foodId",
      name: "CookingIngredients",
      component: () => import("@/cooking/CookingIngredients.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
    {
      path: "cookingsettings",
      name: "CookingSettings",
      component: () => import("@/cooking/CookingSettings.vue"),
      props: true,
      meta: {
        groupName: "Cooking",
        requiresAuth: true,
      },
    },
  ],
};

let busAdministration = {
  path: "/busadministration",
  component: DashboardLayout,
  name: "BusAdministration",
  children: [
    {
      path: "buses",
      name: "Buses",
      component: () => import("@/buses/Buses"),
      meta: {
        groupName: "BusAdministration",
        requiresAuth: true,
      },
    },
    {
      path: "busesmonitoring",
      name: "BusesMonitoring",
      component: () => import("@/buses/BusesMonitoring"),
      meta: {
        groupName: "BusAdministration",
        requiresAuth: true,
      },
    },

    {
      path: "busstudents/:busId",
      name: "BusStudents",
      component: () => import("@/buses/BusStudents.vue"),
      props: true,
      meta: {
        groupName: "BusAdministration",
        requiresAuth: true,
      },
    },
  ],
};
const fb = require("@/firebaseConfig.js");

const routers = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    applicationPages,
    authBasicPages,
    userPages,
    profilePages,
    OrganizationSetting,
    myClassPages,
    cooking,
    busAdministration,
    {
      path: "/homepage",
      name: "HomePage",
      component: () => import("@/views/HomePage.vue"),
    },
    {
      path: "/privacy.policy",
      name: "PrivacyPolicy",
      component: () => import("@/views/PrivacyPolicy.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/",
      name: "Dashboard",
      redirect: fb.COOKING_APP ? "/homepage" : "/pages/dashboards/default",
      component: DashboardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/pages/dashboards/default",
          name: "Default",
          component: Dashboard,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        //for managers
        {
          path: "/sms/manager/tsaghuvaarilalt",
          name: "tsaghuvaarilalt",
          component: () => import("@/sms/manager/TsagHuvaarilalt.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/lesson/:path",
          name: "PageLesson",
          component: PageLesson,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lessonesis/:path",
          name: "PageLessonESIS",
          component: PageLessonESIS,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lessonscores/:path",
          name: "PageLessonScores",
          component: () => import("@/views/Dashboard/PageLessonScores.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lessonesis/:path",
          name: "PageLessonESISSongon",
          component: () => import("@/views/Dashboard/PageLessonESISSongon.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lesson/homeworks/:path",
          name: "StudentsHomeWorksWidget",
          component: () =>
            import("@/views/Dashboard/Widgets/StudentsHomeWorksWidget.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lessonscore/:path",
          name: "PageLessonScore",
          component: PageLessonScore,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/lessons",
          name: "PageLessons",
          component: PageLessons,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/lessonsdatabase",
          name: "PageLessonsDatabase",
          component: () => import("@/views/Dashboard/PageLessonsDatabase.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/attendanceanalysis",
          name: "AttendanceAnalysis",
          component: AttendanceAnalysis,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/allattendanceanalysis",
          name: "AllAttendanceAnalysis",
          component: () =>
            import("@/views/Pages/Irz/AllAttendanceAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        {
          path: "/pages/dashboards/attendanceanalysistable",
          name: "AttendanceAnalysisTable",
          component: () =>
            import("@/views/Pages/Irz/AttendanceAnalysisTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/teacherlessonattendancetable",
          name: "TeacherLessonAttendanceTable",
          component: () =>
            import("@/views/Pages/Irz/TeacherLessonAttendanceTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/songonlessons",
          name: "SongonLessons",
          component: () => import("@/sms/manager/SongonLessons.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/additionallessons",
          name: "AdditionalLessons",
          component: () => import("@/sms/manager/AdditionalLessons.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/lessonstmp",
          name: "PageLessonsTMP",
          component: PageLessonsTMP,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/sdepartments",
          name: "SDepartments",
          component: SDepartments,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/sdepartmentstable",
          name: "SDepartmentsTable",
          component: () => import("@/views/Dashboard/SDepartmentsTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/onlineclasses",
          name: "Onlineclasses",
          component: () => import("@/views/Dashboard/OnlineClasses.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        {
          path: "/pages/dashboards/lessoncategoriesbydepartments",
          name: "LessonCategoriesByDepartments",
          component: LessonCategoriesByDepartments,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        {
          path: "/pages/dashboards/lessoncategories",
          name: "LessonCategories",
          component: LessonCategories,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/PageLessonPreview/:path",
          name: "PageLessonPreview",
          component: PageLessonPreview,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },

        {
          path: "/pages/dashboards/crm",
          name: "Crm",
          component: Crm,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/calendar",
          name: "Calendar",
          component: Calendar,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/calendary2",
          name: "Calendary",
          component: Calendary,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/assignments",
          name: "PageAssignments",
          component: PageAssignments,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/pagesorilassignments",
          name: "PageSorilAssignments",
          component: PageSorilAssignments,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/specialassignments",
          name: "SpecialAssignments",
          component: () =>
            import("@/views/Pages/Assignments/SpecialAssignments.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/myassignments",
          name: "MyAssignments",
          component: () =>
            import("@/views/Pages/Assignments/MyAssignments.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/schoolreports",
          name: "SchoolReports",
          component: () => import("@/views/Pages/Analyzes/SchoolReports.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/specialanalysis",
          name: "SpecialAnalysis",
          component: () => import("@/views/Pages/Analyzes/SpecialAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/eyeshanalysis",
          name: "EyeshAnalysis",
          component: () => import("@/views/Pages/Analyzes/EyeshAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studenteyeshanalysis",
          name: "StudentEyeshAnalysis",
          component: () =>
            import("@/views/Pages/Analyzes/StudentEyeshAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studenteyeshspecialanalysis",
          name: "StudentEyeshSpecialAnalysis",
          component: () =>
            import("@/views/Pages/Analyzes/StudentEyeshSpecialAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/schoolassignments",
          name: "SchoolAssignments",
          component: () =>
            import("@/views/Pages/Assignments/SchoolAssignments.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/butguizetgelassignments",
          name: "BUTguizetgelAssignments",
          component: () =>
            import("@/views/Pages/Assignments/BUTguizetgelAssignments.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/teachersoril",
          name: "",
          component: () => import("@/views/Pages/Teachers/TeacherSoril.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/schoolassignmentsforstudents",
          name: "SchoolAssignmentsForStudents",
          component: () =>
            import(
              "@/views/Pages/Assignments/SchoolAssignmentsForStudents.vue"
            ),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        {
          path: "/pages/dashboards/pagesorilassignmentsbygrandit",
          name: "PageSorilAssignmentsByGrandit",
          component: PageSorilAssignmentsByGrandit,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/pagesorilassignmentsbygrandit2",
          name: "PageSorilAssignmentsByGrandit2",
          component: PageSorilAssignmentsByGrandit2,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/scoreanalysis",
          name: "ScoreAnalysis",
          component: () =>
            import("@/views/Pages/Assignments/ScoreAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/questionanalysis",
          name: "QuestionAnalysis",
          component: () =>
            import("@/views/Pages/Assignments/QuestionAnalysis.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },

        {
          path: "/pages/dashboards/studentslessons",
          name: "StudentsLessons",
          component: StudentsLessons,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studentssorils",
          name: "StudentsSorils",
          component: StudentsSorils,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studentssorils800",
          name: "StudentsSorils800",
          component: () =>
            import("@/views/Pages/Students/StudentsSorils800.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studentssorilsofschools2",
          name: "StudentsSorilsOfSchools2",
          component: () =>
            import("@/views/Pages/Students/StudentsSorilsOfSchools2.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        //
        {
          path: "/pages/dashboards/studentsindexedsorils",
          name: "StudentsIndexedSorils",
          component: () =>
            import("@/views/Pages/Students/StudentsIndexedSorils.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/indexedsorils",
          component: () =>
            import("@/views/Pages/Assignments/IndexedSorils.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/studentlessondetail/:path",
          name: "StudentLessonDetail",
          component: StudentLessonDetail,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentselectlessons",
          name: "StudentSelectLessons",
          component: StudentSelectLessons,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentcalendary",
          name: "StudentCalendary",
          component: () =>
            import("@/views/Pages/Students/StudentCalendary.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentkindergartenirz",
          name: "StudentKinderGartenIrz",
          component: () =>
            import("@/views/Pages/Students/StudentKinderGartenIrz.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentunelgee2",
          name: "",
          component: () => import("@/views/Pages/Scores/Unelgee2Students.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentslessontable",
          name: "StudentsLessonTable",
          component: () =>
            import("@/views/Pages/Students/StudentsLessonTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentshowassignmentresults/:sorilId",
          name: "StudentShowAssignmentResults",
          component: StudentShowAssignmentResults,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },

        {
          path: "/pages/dashboards/studentshowassignmentallresultsdetail/:sorilId",
          name: "StudentShowAssignmentAllResultsDetail",
          component: StudentShowAssignmentAllResultsDetail,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/pagesorilassignmentsbygrandit",
          name: "PageSorilAssignmentsByGrandit",
          component: PageSorilAssignmentsByGrandit,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/assignmentsettings",
          name: "AssignmentSettings",
          component: () =>
            import("@/views/Pages/Assignments/AssignmentSettings.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/assignmentschoolsettings",
          name: "AssignmentSchoolSettings",
          component: () =>
            import("@/views/Pages/Assignments/AssignmentSchoolSettings.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/dashboards/allquestionsanalysis/:asuulga/:numberOfQuestions",
          name: "AllQuestionsAnalysis",
          component: AllQuestionsAnalysis,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/reportschool/:results/:asuulga",
          name: "ReportSchool",
          component: ReportSchool,
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentlessonchart/",
          name: "StudentLessonChart",
          component: () => import("@/views/Charts/StudentLessonChart.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studentsoriltable/",
          name: "StudentSorilTable",
          component: () => import("@/views/Charts/StudentSorilTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/pages/dashboards/studenteyeshanalysisbysorils/",
          name: "StudentEyeshAnalysisBySorils",
          component: () =>
            import("@/views/Pages/Analyzes/StudentEyeshAnalysisBySorils.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
          props: true,
        },
        //citymanager
        {
          path: "/pages/dashboards/cityassignments",
          name: "CityAssignments",
          component: () =>
            import("@/views/Pages/Assignments/CityAssignments.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/parents/dailyirz",
          name: "ParentsDailyIrz",
          props: true,
          component: () => import("@/views/Pages/Irz/ParentsDailyIrz.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/parents/paymenttable",
          name: "ParentsPaymentTable",
          props: true,
          component: () => import("@/views/Pages/Irz/ParentsPaymentTable.vue"),
          meta: {
            groupName: "Dashboards",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/charts",
      name: "Charts",
      component: Charts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/shalgaltediting/:xpath",
      name: "XShalgaltEditing",
      props: true,
      component: () => import("@/views/Pages/Assignments/XShalgaltEditing"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/shalgalteditingeyesh/:xpath",
      name: "XShalgaltEditingEyesh",
      props: true,
      component: () =>
        import("@/views/Pages/Assignments/XShalgaltEditingEyesh"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/xshalgalteyeshview/:xpath",
      name: "XShalgaltEyeshView",
      props: true,
      component: () => import("@/views/Pages/Assignments/XShalgaltEyeshView"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/xshalgalteyeshviewdialog/:xpath",
      name: "XShalgaltEyeshViewDialog",
      props: true,
      component: () =>
        import("@/views/Pages/Assignments/XShalgaltEyeshViewDialog"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/shalgaltvieweyesh/:xpath",
      name: "XShalgaltViewEyesh",
      props: true,
      component: () => import("@/views/Pages/Assignments/XShalgaltViewEyesh"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/editingpublic/:sharedLink",
      name: "XShalgaltEditingEyeshPublic",
      props: true,
      component: () =>
        import("@/views/Pages/Assignments/XShalgaltEditingEyeshPublic"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/shalgaltsharing/:shalgaltIdd",
      name: "XShalgaltSharing",
      props: true,
      component: () => import("@/views/Pages/Assignments/XShalgaltSharing"),
    },
    {
      path: "/xshalgalteyeshsharingoutside/:shalgaltIdd",
      name: "XShalgaltEyeshSharingOutside",
      props: true,
      component: () =>
        import("@/views/Pages/Assignments/XShalgaltEyeshSharingOutside"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/xshalgalteyeshsharing/:shalgaltIdd:/:fromEYESH",
      name: "XShalgaltEyeshSharing",
      props: true,
      component: () =>
        import("@/views/Pages/Assignments/XShalgaltEyeshSharing"),
    },
    {
      path: "/lessonexamanswering/:shalgaltIdd:/:fromLessonExamId",
      name: "LessonExamAnswering",
      props: true,
      component: () => import("@/views/Pages/Assignments/LessonExamAnswering"),
    },
    {
      path: "/shalgaltresults/:item",
      name: "XShalgaltResults",
      props: true,
      component: () => import("@/views/Pages/Assignments/XShalgaltResults"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/publicstudentsscore/:sorilId",
      name: "PublicStudentsScore",
      component: PublicStudentsScore,
      props: true,
    },
    {
      path: "/pages/dashboards/studentshowassignmentallresults2/:sorilId",
      name: "StudentShowAssignmentAllResults2",
      component: () =>
        import("@/views/Pages/Assignments/XShowAssignmentAllResults2.vue"),
      meta: {
        requiresAuth: false,
      },
      props: true,
    },
    {
      path: "/pages/dashboards/studentshowassignmentalltime/:sorilId",
      name: "XShowAssignmentAllStudentsTime",
      component: () =>
        import("@/views/Pages/Assignments/XShowAssignmentAllStudentsTime.vue"),
      meta: {
        requiresAuth: false,
      },
      props: true,
    },

    {
      path: "/pages/dashboards/studentshowassignmentresultspublic/:sorilId",
      name: "StudentShowAssignmentResultsPublic",
      component: () =>
        import("@/views/Pages/Students/StudentShowAssignmentResultsPublic.vue"),
      props: true,
    },
    {
      path: "/unelgeeediting/:xpath",
      name: "UnelgeeEditing",
      props: true,
      component: () => import("@/unelgee/UnelgeeEditing"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/une/:shalgaltIdd",
      name: "UnelgeeAnswering",
      props: true,
      component: () => import("@/unelgee/UnelgeeAnswering"),
    },
    {
      path: "/UnelgeeResults/:item",
      name: "UnelgeeResults",
      props: true,
      component: () => import("@/unelgee/UnelgeeResults"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active",
});

import { store } from "@/store.js";
routers.beforeEach(async (to, from, next) => {
  if (
    store &&
    store.state &&
    store.state.userData &&
    store.state.userData.ref
  ) {
    store.state.userData.ref.update({
      lastLoggedAt: new Date(),
    });
  }

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  // const requiresPermissionCheck = to.matched.some(x => x.meta.requiresPermissionCheck);

  if (to.matched[1]) {
    const xpath = to.matched[1].path;
    store.state.xRouterLink = xpath;
  }
  const systemLocked = false;

  if (requiresAuth && store.state.userData) {
    if (store.state.userData) {
      if (systemLocked) {
        if (
          store.state.userData.role == "superadmin" ||
          store.state.userData.role == "student"
        ) {
          next();
        } else {
          next({
            name: "Login",
          });
        }
      } else {
        next();
      }
    } else if (store.state.mendleUserRef) {
      next();
    } else {
      next({
        name: "Login",
      });
    }
  } else {
    next();
  }
});

export default routers;

/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import pathify, { make } from "vuex-pathify";
import VuexPersist from "vuex-persist";

const fb = require("./firebaseConfig.js");

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage, // or window.sessionStorage or localForage
  reducer: (state) => ({
    selectedSchoolRef: state.selectedSchoolRef,
    selectedClassGroupTab: state.selectedClassGroupTab,
    mendleUserRef: state.mendleUserRef,
  }),
});

const state = {
  // greeting: 'Hello',
  // name: 'World',
  // scores: [{ group: 'A', score: 11 }, { group: 'B', score: 22 }],
  // score: 22,
  // department: null,
  // departmentRef: null,
  // count: 88,
  // role: null,
  xRouterLink: null,
  userData: null,
  mendleUserRef: null,
  userRefPath: null,
  logged: null,
  loggedEmail: null,
  currentUser: null,
  lessonCategoriesPath: "lessonCategories",
  selectedSchoolRef: null,
  availableSchools: null,
  selectedClassGroupTab: 0,
  calendarRefreshCalled: false,
  ClassSelectedSelectedHalfYearId: 2,

  PROGRAM_PLAN_SCHEMA: [
    { planName: "350+350+350+350+350", academicLevel: "1" },
    { planName: "491+350+350+350+350", academicLevel: "2" },
    { planName: "491+350+350+350+350", academicLevel: "3" },
    { planName: "491+491+350+350+350", academicLevel: "4" },
    { planName: "491+491+491+350+350", academicLevel: "5" },
    { planName: "350+350+350+350", academicLevel: "6" },
    { planName: "350+350+350+350", academicLevel: "7" },
    { planName: "491+350+350+350", academicLevel: "8" },
    { planName: "491+491+350+350", academicLevel: "9" },
    { planName: "398+398+398", academicLevel: "10" },
    { planName: "398+398+398", academicLevel: "11" },
    { planName: "491+398+398", academicLevel: "12" },
  ],
  PROGRAM_PLAN_SCHEMA_XOC: [
    { planName: "491+491+491+491+491", academicLevel: "1" },
    { planName: "491+491+491+491+491", academicLevel: "2" },
    { planName: "453+491+491+491+491", academicLevel: "3" },
    { planName: "240+453+491+491+491", academicLevel: "4" },
    { planName: "240+240+453+491+491", academicLevel: "5" },
    { planName: "491+491+491+491", academicLevel: "6" },
    { planName: "491+491+491+491", academicLevel: "7" },
    { planName: "453+491+491+491", academicLevel: "8" },
    { planName: "220+453+491+491", academicLevel: "9" },
    { planName: "491+491+491", academicLevel: "10" },
    { planName: "491+491+491", academicLevel: "11" },
    { planName: "453+491+491", academicLevel: "12" },
  ],
  calendarButez2324: [
    {
      session: 1,
      months: [
        {
          name: 9,
          start: true,
          days: [
            {
              day: 1,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 1,
              year: 2023,
              start: true,
            },
            {
              day: 4,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 2,
              year: 2023,
            },
            {
              day: 5,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 2,
              year: 2023,
            },
            {
              day: 6,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 2,
              year: 2023,
            },
            {
              day: 7,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 2,
              year: 2023,
            },
            {
              day: 8,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 2,
              year: 2023,
            },

            {
              day: 11,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 3,
              year: 2023,
            },
            {
              day: 12,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 3,
              year: 2023,
            },
            {
              day: 13,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 3,
              year: 2023,
            },
            {
              day: 14,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 3,
              year: 2023,
            },
            {
              day: 15,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 3,
              year: 2023,
            },

            {
              day: 18,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 4,
              year: 2023,
            },
            {
              day: 19,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 4,
              year: 2023,
            },
            {
              day: 20,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 4,
              year: 2023,
            },
            {
              day: 21,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 4,
              year: 2023,
            },
            {
              day: 22,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 4,
              year: 2023,
            },

            {
              day: 25,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 5,
              year: 2023,
            },
            {
              day: 26,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 5,
              year: 2023,
            },
            {
              day: 27,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 5,
              year: 2023,
            },
            {
              day: 28,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 5,
              year: 2023,
            },
            {
              day: 29,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 5,
              year: 2023,
            },
          ],
        },
        {
          name: 10,
          days: [
            {
              day: 2,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 6,
              year: 2023,
            },
            {
              day: 3,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 6,
              year: 2023,
            },
            {
              day: 4,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 6,
              year: 2023,
            },
            {
              day: 5,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 6,
              year: 2023,
            },
            {
              day: 6,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 6,
              year: 2023,
            },

            {
              day: 9,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 7,
              year: 2023,
            },
            {
              day: 10,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 7,
              year: 2023,
            },
            {
              day: 11,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 7,
              year: 2023,
            },
            {
              day: 12,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 7,
              year: 2023,
            },
            {
              day: 13,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 7,
              year: 2023,
            },

            {
              day: 16,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 8,
              year: 2023,
            },
            {
              day: 17,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 8,
              year: 2023,
            },
            {
              day: 18,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 8,
              year: 2023,
            },
            {
              day: 19,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 8,
              year: 2023,
            },
            {
              day: 20,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 8,
              year: 2023,
            },

            {
              day: 23,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 9,
              year: 2023,
            },
            {
              day: 24,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 9,
              year: 2023,
            },
            {
              day: 25,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 9,
              year: 2023,
            },
            {
              day: 26,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 9,
              year: 2023,
            },
            {
              day: 27,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 9,
              year: 2023,
            },

            {
              day: 30,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 10,
              year: 2023,
            },
            {
              day: 31,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 10,
              year: 2023,
            },
          ],
        },
        {
          name: 11,
          end: true,
          days: [
            {
              day: 1,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 10,
              year: 2023,
            },
            {
              day: 2,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 10,
              year: 2023,
            },
            {
              day: 3,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 10,
              year: 2023,
              end: true,
            },
          ],
        },
      ],
      weeks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      weeks2: [
        { weekNumber: 1 },
        { weekNumber: 2 },
        { weekNumber: 3 },
        { weekNumber: 4 },
        { weekNumber: 5 },
        { weekNumber: 6 },
        { weekNumber: 7 },
        { weekNumber: 8 },
        { weekNumber: 9 },
        { weekNumber: 10 },
      ],
      duration: [
        { day: 1, month: 9, year: 2023 },
        { day: 3, month: 11, year: 2023 },
      ],
      year: 2023,
      numberOfWeeks: 10,
    },
    {
      session: 2,
      months: [
        {
          name: 11,
          start: true,
          days: [
            {
              day: 13,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 11,
              year: 2023,
              start: true,
            },
            {
              day: 14,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 11,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 15,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 11,
              year: 2023,
            },
            {
              day: 16,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 11,
              year: 2023,
            },
            {
              day: 17,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 11,
              year: 2023,
            },

            {
              day: 20,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 12,
              year: 2023,
            },
            {
              day: 21,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 12,
              year: 2023,
            },
            {
              day: 22,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 12,
              year: 2023,
            },
            {
              day: 23,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 12,
              year: 2023,
            },
            {
              day: 24,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 12,
              year: 2023,
            },

            {
              day: 27,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 13,
              year: 2023,
            },
            {
              day: 28,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 13,
              year: 2023,
            },
            {
              day: 29,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 13,
              year: 2023,
            },
            {
              day: 30,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 13,
              year: 2023,
            },
          ],
        },
        {
          name: 12,
          end: true,
          days: [
            {
              day: 1,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 13,
              year: 2023,
            },
            {
              day: 4,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 14,
              year: 2023,
            },
            {
              day: 5,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 14,
              year: 2023,
            },
            {
              day: 6,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 14,
              year: 2023,
            },
            {
              day: 7,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 14,
              year: 2023,
            },
            {
              day: 8,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 14,
              year: 2023,
            },
            {
              day: 9,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 14,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 10,
              garag: 7,
              garagName: "Ням",
              weekNumber: 14,
              isHoliday: true,
              year: 2023,
            },

            {
              day: 11,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 15,
              year: 2023,
            },
            {
              day: 12,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 15,
              year: 2023,
            },
            {
              day: 13,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 15,
              year: 2023,
            },
            {
              day: 14,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 15,
              year: 2023,
            },
            {
              day: 15,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 15,
              year: 2023,
            },
            {
              day: 16,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 15,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 17,
              garag: 7,
              garagName: "Ням",
              weekNumber: 15,
              isHoliday: true,
              year: 2023,
            },

            {
              day: 18,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 16,
              year: 2023,
            },
            {
              day: 19,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 16,
              year: 2023,
            },
            {
              day: 20,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 16,
              year: 2023,
            },
            {
              day: 21,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 16,
              year: 2023,
            },
            {
              day: 22,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 16,
              year: 2023,
            },
            {
              day: 23,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 16,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 24,
              garag: 7,
              garagName: "Ням",
              weekNumber: 16,
              isHoliday: true,
              year: 2023,
            },

            {
              day: 25,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 17,
              year: 2023,
            },
            {
              day: 26,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 17,
              year: 2023,
            },
            {
              day: 27,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 17,
              year: 2023,
            },
            {
              day: 28,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 17,
              year: 2023,
            },
            {
              day: 29,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 17,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 30,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 17,
              isHoliday: true,
              year: 2023,
            },
            {
              day: 31,
              garag: 7,
              garagName: "Ням",
              weekNumber: 17,
              isHoliday: true,
              year: 2023,
              end: true,
            },
            // { day: 29, garag: 5, garagName: "Баасан" },
          ],
        },
        // {
        //   name: 1,
        //   days: [
        //     {
        //       day: 1,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 2,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 3,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 4,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 5,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 6,
        //       garag: 6,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 7,
        //       garag: 7,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     /////1
        //     {
        //       day: 1,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 2,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 3,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 4,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 5,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 6,
        //       garag: 6,
        //       garagName: "Бямба",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 7,
        //       garag: 7,
        //       garagName: "Ням",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     //2
        //     {
        //       day: 1,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 2,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 3,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 4,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 5,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 6,
        //       garag: 6,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 7,
        //       garag: 7,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 19,
        //       isHoliday: true,
        //     },
        //     /////2
        //     {
        //       day: 8,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 9,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 10,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 11,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 12,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 13,
        //       garag: 6,
        //       garagName: "Бямба",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 14,
        //       garag: 7,
        //       garagName: "Ням",
        //       year: 2024,
        //       weekNumber: 20,
        //       isHoliday: true,
        //     },
        //     //21
        //     {
        //       day: 15,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 16,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 17,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 18,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 19,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 20,
        //       garag: 6,
        //       garagName: "Бямба",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 21,
        //       garag: 7,
        //       garagName: "Ням",
        //       year: 2024,
        //       weekNumber: 21,
        //       isHoliday: true,
        //     },
        //     //22
        //     {
        //       day: 22,
        //       garag: 1,
        //       garagName: "Даваа",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 23,
        //       garag: 2,
        //       garagName: "Мягмар",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 24,
        //       garag: 3,
        //       garagName: "Лxагва",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 25,
        //       garag: 4,
        //       garagName: "Пүрэв",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 26,
        //       garag: 5,
        //       garagName: "Баасан",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 27,
        //       garag: 6,
        //       garagName: "Бямба",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     {
        //       day: 28,
        //       garag: 7,
        //       garagName: "Ням",
        //       year: 2024,
        //       weekNumber: 22,
        //       isHoliday: true,
        //     },
        //     //23
        //   ],
        // },
      ],
      weeks: [11, 12, 13, 14, 15, 16, 17],
      weeks2: [
        { weekNumber: 11 },
        { weekNumber: 12 },
        { weekNumber: 13 },
        { weekNumber: 14 },
        { weekNumber: 15 },
        { weekNumber: 16 },
        { weekNumber: 17 },
      ],
      duration: [
        { day: 13, month: 11, year: 2023 },
        { day: 28, month: 12, year: 2023 },
      ],
      year: 2023,
      numberOfWeeks: 7,
    },
    {
      session: 3,
      months: [
        {
          name: 1,
          start: true,
          days: [
            // {
            //   day: 28,
            //   garag: 7,
            //   garagName: "Ням",
            //   weekNumber: 18,
            //   year: 2024,
            //   isHoliday: true,
            // },
            {
              day: 29,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 19,
              year: 2024,
              start: true,
            },
            {
              day: 30,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 19,
              year: 2024,
            },
            {
              day: 31,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 19,
              year: 2024,
            },
          ],
        },
        {
          name: 2,
          days: [
            {
              day: 1,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 19,
              year: 2024,
            },
            {
              day: 2,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 19,
              year: 2024,
            },
            {
              day: 3,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 19,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 4,
              garag: 7,
              garagName: "Ням",
              weekNumber: 19,
              isHoliday: true,
              year: 2024,
            },
            //week 20
            {
              day: 5,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 20,
              year: 2024,
            },
            {
              day: 6,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 20,
              year: 2024,
            },
            {
              day: 7,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 20,
              year: 2024,
            },
            {
              day: 8,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 20,
              year: 2024,
            },
            {
              day: 9,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 20,
              year: 2024,
            },
            {
              day: 10,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 20,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 11,
              garag: 7,
              garagName: "Ням",
              weekNumber: 20,
              isHoliday: true,
              year: 2024,
            },
            //week 21
            {
              day: 12,
              garag: 1,
              garagName: "Даваа",
              isHoliday: true,
              weekNumber: 21,
              year: 2024,
            },
            {
              day: 13,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 21,
              year: 2024,
            },
            {
              day: 14,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 21,
              year: 2024,
            },
            {
              day: 15,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 21,
              year: 2024,
            },
            {
              day: 16,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 21,
              year: 2024,
            },
            {
              day: 17,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 21,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 18,
              garag: 7,
              garagName: "Ням",
              weekNumber: 21,
              isHoliday: true,
              year: 2024,
            },
            // weeek 22
            {
              day: 19,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 22,
              year: 2024,
            },
            {
              day: 20,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 22,
              year: 2024,
            },
            {
              day: 21,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 22,
              year: 2024,
            },
            {
              day: 22,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 22,
              year: 2024,
            },
            {
              day: 23,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 22,
              year: 2024,
            },
            {
              day: 24,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 22,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 25,
              garag: 7,
              garagName: "Ням",
              weekNumber: 22,
              isHoliday: true,
              year: 2024,
            },
            // weeek 23
            {
              day: 26,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 23,
              year: 2024,
            },
            {
              day: 27,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 23,
              year: 2024,
            },
            {
              day: 28,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 23,
              year: 2024,
            },
            {
              day: 29,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 23,
              year: 2024,
            },
          ],
        },
        {
          name: 3,
          end: true,
          days: [
            {
              day: 1,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 23,
              year: 2024,
            },
            {
              day: 2,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 23,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 3,
              garag: 7,
              garagName: "Ням",
              weekNumber: 23,
              isHoliday: true,
              year: 2024,
            },
            //week 24
            {
              day: 4,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 24,
              year: 2024,
            },
            {
              day: 5,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 24,
              year: 2024,
            },
            {
              day: 6,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 24,
              year: 2024,
            },
            {
              day: 7,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 24,
              year: 2024,
            },
            {
              day: 8,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 24,
              year: 2024,
              isHoliday: true,
            },
            {
              day: 9,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 24,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 10,
              garag: 7,
              garagName: "Ням",
              weekNumber: 24,
              isHoliday: true,
              year: 2024,
            },
            //weeek 25
            {
              day: 11,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 25,
              year: 2024,
            },
            {
              day: 12,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 25,
              year: 2024,
            },
            {
              day: 13,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 25,
              year: 2024,
            },
            {
              day: 14,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 25,
              year: 2024,
            },
            {
              day: 15,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 25,
              year: 2024,
            },
            {
              day: 16,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 25,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 17,
              garag: 7,
              garagName: "Ням",
              weekNumber: 25,
              isHoliday: true,
              year: 2024,
            },
            //week 26
            {
              day: 18,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 26,
              year: 2024,
            },
            {
              day: 19,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 26,
              year: 2024,
            },
            {
              day: 20,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 26,
              year: 2024,
            },
            {
              day: 21,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 26,
              year: 2024,
            },
            {
              day: 22,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 26,
              year: 2024,
            },
            {
              day: 23,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 26,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 24,
              garag: 7,
              garagName: "Ням",
              weekNumber: 26,
              isHoliday: true,
              year: 2024,
            },
            //week 27
            {
              day: 25,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 27,
              year: 2024,
            },
            {
              day: 26,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 27,
              year: 2024,
            },
            {
              day: 27,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 27,
              year: 2024,
            },
            {
              day: 28,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 27,
              year: 2024,
            },
            {
              day: 29,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 27,
              year: 2024,
            },
            {
              day: 30,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 27,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 31,
              garag: 7,
              garagName: "Ням",
              weekNumber: 27,
              isHoliday: true,
              year: 2024,
              end: true,
            },
          ],
        },
      ],
      weeks: [19, 20, 21, 22, 23, 24, 25, 26, 27],
      weeks2: [
        { weekNumber: 19 },
        { weekNumber: 20 },
        { weekNumber: 21 },
        { weekNumber: 22 },
        { weekNumber: 23 },
        { weekNumber: 24 },
        { weekNumber: 25 },
        { weekNumber: 26 },
        { weekNumber: 27 },
      ],
      duration: [
        { day: 29, month: 1, year: 2024 },
        { day: 29, month: 3, year: 2024 },
      ],
      year: 2024,
      numberOfWeeks: 9,
    },
    {
      session: 4,
      months: [
        {
          name: 4,
          start: true,
          days: [
            {
              day: 8,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 28,
              year: 2024,
              start: true,
            },
            {
              day: 9,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 28,
              year: 2024,
            },
            {
              day: 10,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 28,
              year: 2024,
            },
            {
              day: 11,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 28,
              year: 2024,
            },
            {
              day: 12,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 28,
              year: 2024,
            },
            {
              day: 13,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 28,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 14,
              garag: 7,
              garagName: "Ням",
              weekNumber: 28,
              isHoliday: true,
              year: 2024,
            },
            //29
            {
              day: 15,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 29,
              year: 2024,
              start: true,
            },
            {
              day: 16,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 29,
              year: 2024,
            },
            {
              day: 17,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 29,
              year: 2024,
            },
            {
              day: 18,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 29,
              year: 2024,
            },
            {
              day: 19,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 29,
              year: 2024,
            },
            {
              day: 20,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 29,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 21,
              garag: 7,
              garagName: "Ням",
              weekNumber: 29,
              isHoliday: true,
              year: 2024,
            },
            //30
            {
              day: 22,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 30,
              year: 2024,
              start: true,
            },
            {
              day: 23,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 30,
              year: 2024,
            },
            {
              day: 24,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 30,
              year: 2024,
            },
            {
              day: 25,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 30,
              year: 2024,
            },
            {
              day: 26,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 30,
              year: 2024,
            },
            {
              day: 27,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 30,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 28,
              garag: 7,
              garagName: "Ням",
              weekNumber: 30,
              isHoliday: true,
              year: 2024,
            },
            //31
            {
              day: 29,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 31,
              year: 2024,
              start: true,
            },
            {
              day: 30,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 31,
              year: 2024,
            },
          ],
        },
        {
          name: 5,
          days: [
            {
              day: 1,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 31,
              year: 2024,
            },
            {
              day: 2,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 31,
              year: 2024,
            },
            {
              day: 3,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 31,
              year: 2024,
            },
            {
              day: 4,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 31,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 5,
              garag: 7,
              garagName: "Ням",
              weekNumber: 31,
              isHoliday: true,
              year: 2024,
            },
            //32
            {
              day: 6,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 32,
              year: 2024,
              start: true,
            },
            {
              day: 7,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 32,
              year: 2024,
            },
            {
              day: 8,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 32,
              year: 2024,
            },
            {
              day: 9,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 32,
              year: 2024,
            },
            {
              day: 10,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 32,
              year: 2024,
            },
            {
              day: 11,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 32,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 12,
              garag: 7,
              garagName: "Ням",
              weekNumber: 32,
              isHoliday: true,
              year: 2024,
            },
            //33
            {
              day: 13,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 33,
              year: 2024,
              start: true,
            },
            {
              day: 14,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 33,
              year: 2024,
            },
            {
              day: 15,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 33,
              year: 2024,
            },
            {
              day: 16,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 33,
              year: 2024,
            },
            {
              day: 17,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 33,
              year: 2024,
            },
            {
              day: 18,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 33,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 19,
              garag: 7,
              garagName: "Ням",
              weekNumber: 33,
              isHoliday: true,
              year: 2024,
            },
            //34
            {
              day: 20,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 34,
              year: 2024,
              start: true,
            },
            {
              day: 21,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 34,
              year: 2024,
            },
            {
              day: 22,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 34,
              year: 2024,
            },
            {
              day: 23,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 34,
              year: 2024,
              isHoliday: true,
            },
            {
              day: 24,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 34,
              year: 2024,
            },
            {
              day: 25,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 34,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 26,
              garag: 7,
              garagName: "Ням",
              weekNumber: 34,
              isHoliday: true,
              year: 2024,
            },
            //35
            {
              day: 27,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 35,
              year: 2024,
              start: true,
            },
            {
              day: 28,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 35,
              year: 2024,
            },
            {
              day: 29,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 35,
              year: 2024,
            },
            {
              day: 30,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 35,
              year: 2024,
            },
            {
              day: 31,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 35,
              year: 2024,
            },
          ],
        },

        {
          name: 6,
          days: [
            {
              day: 1,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 35,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 2,
              garag: 7,
              garagName: "Ням",
              weekNumber: 35,
              isHoliday: true,
              year: 2024,
            },
            //36
            {
              day: 3,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 36,
              year: 2024,
              start: true,
            },
            {
              day: 4,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 36,
              year: 2024,
            },
            {
              day: 5,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 36,
              year: 2024,
            },
            {
              day: 6,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 36,
              year: 2024,
            },
            {
              day: 7,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 36,
              year: 2024,
            },
            {
              day: 8,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 36,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 9,
              garag: 7,
              garagName: "Ням",
              weekNumber: 36,
              isHoliday: true,
              year: 2024,
            },
            //37
            {
              day: 10,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 37,
              year: 2024,
              start: true,
            },
            {
              day: 11,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 37,
              year: 2024,
            },
            {
              day: 12,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 37,
              year: 2024,
            },
            {
              day: 13,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 37,
              year: 2024,
            },
            {
              day: 14,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 37,
              year: 2024,
            },
            {
              day: 15,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 37,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 16,
              garag: 7,
              garagName: "Ням",
              weekNumber: 37,
              isHoliday: true,
              year: 2024,
            },
          ],
        },
      ],
      weeks: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
      weeks2: [
        { weekNumber: 28 },
        { weekNumber: 29 },
        { weekNumber: 30 },
        { weekNumber: 31 },
        { weekNumber: 32 },
        { weekNumber: 33 },
        { weekNumber: 34 },
        { weekNumber: 35 },
        { weekNumber: 36 },
      ],

      duration: [
        { day: 8, month: 4, year: 2024 },
        { day: 15, month: 6, year: 2024 },
      ],
      year: 2024,
      numberOfWeeks: 9,
      cutOff: {
        1: {
          end: { day: 31, month: 5, year: 2024 },
        },
        2: {
          end: { day: 31, month: 5, year: 2024 },
        },
        3: {
          end: { day: 7, month: 6, year: 2024 },
        },
        4: {
          end: { day: 7, month: 6, year: 2024 },
        },
        5: {
          end: { day: 7, month: 6, year: 2024 },
        },
        6: {
          end: { day: 7, month: 6, year: 2024 },
        },
        7: {
          end: { day: 7, month: 6, year: 2024 },
        },
        8: {
          end: { day: 7, month: 6, year: 2024 },
        },
        9: {
          end: { day: 7, month: 6, year: 2024 },
        },
        10: {
          end: { day: 7, month: 6, year: 2024 },
        },
        11: {
          end: { day: 7, month: 6, year: 2024 },
        },
        12: {
          end: { day: 7, month: 6, year: 2024 },
        },
      },
    },
  ],
  calendarButez2: [
    {
      session: 1,
      months: [
        {
          name: 9,
          start: true,
          days: [
            {
              day: 2,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 1,
              year: 2024,
            },
            {
              day: 3,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 1,
              year: 2024,
            },
            {
              day: 4,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 1,
              year: 2024,
            },
            {
              day: 5,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 1,
              year: 2024,
            },
            {
              day: 6,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 1,
              year: 2024,
            },
            {
              day: 7,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 1,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 8,
              garag: 7,
              garagName: "Ням",
              weekNumber: 1,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 9,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 2,
              year: 2024,
            },
            {
              day: 10,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 2,
              year: 2024,
            },
            {
              day: 11,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 2,
              year: 2024,
            },
            {
              day: 12,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 2,
              year: 2024,
            },
            {
              day: 13,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 2,
              year: 2024,
            },

            {
              day: 14,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 2,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 15,
              garag: 7,
              garagName: "Ням",
              weekNumber: 2,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 16,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 3,
              year: 2024,
            },
            {
              day: 17,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 3,
              year: 2024,
            },
            {
              day: 18,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 3,
              year: 2024,
            },
            {
              day: 19,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 3,
              year: 2024,
            },
            {
              day: 20,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 3,
              year: 2024,
            },

            {
              day: 21,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 3,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 22,
              garag: 7,
              garagName: "Ням",
              weekNumber: 3,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 23,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 4,
              year: 2024,
            },
            {
              day: 24,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 4,
              year: 2024,
            },
            {
              day: 25,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 4,
              year: 2024,
            },
            {
              day: 26,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 4,
              year: 2024,
            },
            {
              day: 27,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 4,
              year: 2024,
            },
            {
              day: 28,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 4,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 29,
              garag: 7,
              garagName: "Ням",
              weekNumber: 4,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 30,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 5,
              year: 2024,
            },
          ],
        },
        {
          name: 10,
          days: [
            {
              day: 1,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 5,
              year: 2024,
            },
            {
              day: 2,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 5,
              year: 2024,
            },
            {
              day: 3,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 5,
              year: 2024,
            },
            {
              day: 4,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 5,
              year: 2024,
            },
            {
              day: 5,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 5,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 6,
              garag: 7,
              garagName: "Ням",
              weekNumber: 5,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 7,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 6,
              year: 2024,
            },
            {
              day: 8,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 6,
              year: 2024,
            },
            {
              day: 9,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 6,
              year: 2024,
            },
            {
              day: 10,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 6,
              year: 2024,
            },
            {
              day: 11,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 6,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 12,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 6,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 13,
              garag: 7,
              garagName: "Ням",
              weekNumber: 6,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 14,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 7,
              year: 2024,
            },
            {
              day: 15,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 7,
              year: 2024,
            },
            {
              day: 16,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 7,
              year: 2024,
            },
            {
              day: 17,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 7,
              year: 2024,
            },
            {
              day: 18,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 7,
              year: 2024,
            },
            {
              day: 19,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 7,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 20,
              garag: 7,
              garagName: "Ням",
              weekNumber: 7,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 21,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 8,
              year: 2024,
            },
            {
              day: 22,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 8,
              year: 2024,
            },
            {
              day: 23,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 8,
              year: 2024,
            },
            {
              day: 24,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 8,
              year: 2024,
            },
            {
              day: 25,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 8,
              year: 2024,
            },
            {
              day: 26,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 8,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 27,
              garag: 7,
              garagName: "Ням",
              weekNumber: 8,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 28,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 9,
              year: 2024,
              isSelfHomeWorked: true,
            },
            {
              day: 29,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 9,
              year: 2024,
              isSelfHomeWorked: true,
            },
            {
              day: 30,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 9,
              year: 2024,
              isSelfHomeWorked: true,
            },
            {
              day: 31,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 9,
              year: 2024,
              isSelfHomeWorked: true,
            },
          ],
        },
        {
          name: 11,
          end: true,
          days: [
            {
              day: 1,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 9,
              year: 2024,
              isSelfHomeWorked: true,
            },
            {
              day: 2,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 9,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 3,
              garag: 7,
              garagName: "Ням",
              weekNumber: 9,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 4,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 10,
              year: 2024,
            },
            {
              day: 5,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 10,
              year: 2024,
            },
            {
              day: 6,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 10,
              year: 2024,
            },
            {
              day: 7,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 10,
              year: 2024,
            },
            {
              day: 8,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 10,
              year: 2024,
            },
            {
              day: 9,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 10,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 10,
              garag: 7,
              garagName: "Ням",
              weekNumber: 10,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 11,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 11,
              year: 2024,
            },
            {
              day: 12,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 11,
              year: 2024,
            },
            {
              day: 13,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 11,
              year: 2024,
            },
            {
              day: 14,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 11,
              year: 2024,
            },
            {
              day: 15,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 11,
              year: 2024,
            },
            {
              day: 16,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 11,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 17,
              garag: 7,
              garagName: "Ням",
              weekNumber: 11,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            //
            {
              day: 18,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 12,
              year: 2024,
            },
            {
              day: 19,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 12,
              year: 2024,
            },
            {
              day: 20,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 12,
              year: 2024,
            },
            {
              day: 21,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 12,
              year: 2024,
            },
            {
              day: 22,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 12,
              year: 2024,
            },
            {
              day: 23,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 12,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 24,
              garag: 7,
              garagName: "Ням",
              weekNumber: 12,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            //

            {
              day: 25,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 13,
              year: 2024,
            },
            {
              day: 26,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 13,
              year: 2024,
            },
            {
              day: 27,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 13,
              year: 2024,
            },
            {
              day: 28,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 13,
              year: 2024,
            },
            {
              day: 29,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 13,
              year: 2024,
            },
            {
              day: 30,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 13,
              isHoliday: true,
              year: 2024,
            },
          ],
        },

        ///@12
        {
          name: 12,
          end: true,
          days: [
            {
              day: 1,
              garag: 7,
              garagName: "Ням",
              weekNumber: 13,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 2,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 14,
              year: 2024,
            },
            {
              day: 3,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 14,
              year: 2024,
            },
            {
              day: 4,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 14,
              year: 2024,
            },
            {
              day: 5,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 14,
              year: 2024,
            },
            {
              day: 6,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 14,
              year: 2024,
            },
            {
              day: 7,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 14,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 8,
              garag: 7,
              garagName: "Ням",
              weekNumber: 14,
              isHoliday: true,
              year: 2024,
              end: true,
            },

            {
              day: 9,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 15,
              year: 2024,
            },
            {
              day: 10,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 15,
              year: 2024,
            },
            {
              day: 11,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 15,
              year: 2024,
            },
            {
              day: 12,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 15,
              year: 2024,
            },
            {
              day: 13,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 15,
              year: 2024,
            },
            {
              day: 14,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 15,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 15,
              garag: 7,
              garagName: "Ням",
              weekNumber: 15,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            //
            {
              day: 16,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 16,
              year: 2024,
            },
            {
              day: 17,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 16,
              year: 2024,
            },
            {
              day: 18,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 16,
              year: 2024,
            },
            {
              day: 19,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 16,
              year: 2024,
            },
            {
              day: 20,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 16,
              year: 2024,
            },
            {
              day: 21,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 16,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 22,
              garag: 7,
              garagName: "Ням",
              weekNumber: 16,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            //

            {
              day: 23,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 17,
              year: 2024,
            },
            {
              day: 24,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 17,
              year: 2024,
            },
            {
              day: 25,
              garag: 3,
              garagName: "Лxагва",
              weekNumber: 17,
              year: 2024,
            },
            {
              day: 26,
              garag: 4,
              garagName: "Пүрэв",
              weekNumber: 17,
              year: 2024,
            },
            {
              day: 27,
              garag: 5,
              garagName: "Баасан",
              weekNumber: 17,
              year: 2024,
            },
            {
              day: 28,
              garag: 6,
              garagName: "Бямба",
              weekNumber: 17,
              isHoliday: true,
              year: 2024,
            },
            {
              day: 29,
              garag: 7,
              garagName: "Ням",
              weekNumber: 17,
              isHoliday: true,
              year: 2024,
              end: true,
            },
            {
              day: 30,
              garag: 1,
              garagName: "Даваа",
              weekNumber: 18,
              year: 2024,
            },
            {
              day: 31,
              garag: 2,
              garagName: "Мягмар",
              weekNumber: 18,
              year: 2024,
            },
          ],
        },
      ],
      weeks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      weeks2: [
        { weekNumber: 1 },
        { weekNumber: 2 },
        { weekNumber: 3 },
        { weekNumber: 4 },
        { weekNumber: 5 },
        { weekNumber: 6 },
        { weekNumber: 7 },
        { weekNumber: 8 },
        { weekNumber: 9 },
        { weekNumber: 10 },
        { weekNumber: 11 },
        { weekNumber: 12 },
        { weekNumber: 13 },
        { weekNumber: 14 },
        { weekNumber: 15 },
        { weekNumber: 16 },
        { weekNumber: 17 },
        { weekNumber: 18 },
      ],
      duration: [
        { day: 2, month: 9, year: 2024 },
        { day: 27, month: 12, year: 2024 },
      ],
      year: 2024,
      numberOfWeeks: 18,
    },
  ],
  yearSemesters: [1,2,3],
  runningSemester: 1,
  closeESIS_call: false,
  middleSystemInfo: {
    ClientId: "p538fM0KhJ",
    ClientSecret: "6Mb9z0X7SJ48WBg2er3f",
  },
};

const mutations = make.mutations(state);
const actions = {
  logout({ state, actions }) {
    state.userData.ref
      .update({
        lastLoggedOutAt: new Date(),
      })
      .then(() => {
        fb.db
          .collection("logs")
          .doc(state.userData.id)
          .update({
            loggedOutAt: new Date(),
            logged: false,
          })
          .then(() => {
            actions.clearData();
          });
      });
  },
  clearData({ commit }) {
    // commit('setCurrentUser', null)
    commit("setSelectedSchoolRef", null);
    commit("setUserData", null);
  },
};
// const vuexLocalStorage = new VuexPersist({
//   key: 'vuex',
//   storage: window.localStorage,
// })

export const store = new Vuex.Store({
  plugins: [pathify.plugin, vuexLocalStorage.plugin],
  state,
  mutations,
  actions,
});
